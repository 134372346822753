<template>
  <div class="content-card">
    <div class="text-right pb-3 d-md-none">
      <b-button variant="primary" size="sm" v-b-toggle.collapse-filtros>
        <v-icon name="filter" width="16"></v-icon>
        {{ $t("Filtrar") }}
      </b-button>
    </div>

    <filtros-listado-mobile>
      <b-row class="align-items-center" id="filtrosDashboard">
        <b-col cols="12" md="2">
          <b-form-group label="Cuadro de mando" class="d-md-block d-none">
            <b-form-select size="md" :value="cuadroId" @change="cambiarCuadro" :options="comboCuadro" />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="2">
          <b-form-group class="d-md-block d-none">
            <template #label>
              Zona
              <span class="numhint">({{ numZonas }})</span>
            </template>
            <b-form-select size="md" v-model="zona" :options="comboZonas" @input="aplicarFiltros" />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="3" lg="2">
          <filtro-fecha label="Desde" v-model="desde" @input="aplicarFiltros" />
        </b-col>
        <b-col cols="12" md="3" lg="2">
          <filtro-fecha label="Hasta" v-model="hasta" @input="aplicarFiltros" />
        </b-col>
      </b-row>
    </filtros-listado-mobile>

    <graficos-resumen v-if="hayValores" />

    <wordcloud-causas v-if="hayValores" :recuento="wordcloudCausas"></wordcloud-causas>

    <SinIndicadores v-if="!hayValores" :cuadroMandoId="cuadroId" />
  </div>
</template>

<script>
import GraficosResumen from "./GraficosResumen";
import FiltroFecha from "./FiltroFecha";
import SinIndicadores from "./SinIndicadores";
import WordcloudCausas from "./WordcloudCausas";
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import { parseDate } from '@/services/date';

export default {
  components: {
    FiltroFecha,
    GraficosResumen,
    SinIndicadores,
    WordcloudCausas,
  },
  computed: {
    ...mapState("dashboard", ["cuadroMando", "filtros", "zonas", "cuadros", "wordcloudCausas"]),
    ...mapGetters("dashboard", ["hayValores"]),
    zona: {
      get() {
        return this.filtros.zona;
      },
      set(value) {
        this.setZona(value);
      },
    },
    desde: {
      get() {
        return this.filtros.desde;
      },
      set(value) {
        this.setDesde(value);
      },
    },
    hasta: {
      get() {
        return this.filtros.hasta;
      },
      set(value) {
        this.setHasta(value);
      },
    },
    cuadroId: {
      get() {
        return this.cuadroMando.id;
      },
      set(value) {
        this.setCuadroMandoId(value);
      },
    },
    comboZonas() {
      const zonas = this.zonas.map((zu) => {
        return {
          text: zu.zona + " (" + zu.usos + " mediciones)",
          value: zu.zona,
        };
      });

      const totalUsos = this.zonas.reduce((acc, val) => acc + val.usos, 0);

      const options = [{ text: `Todas (${totalUsos} mediciones)`, value: "" }];
      options.push(...zonas);
      return options;
    },
    comboCuadro() {
      return this.cuadros.map((cm) => {
        return {
          text: cm.nombre,
          value: cm.id,
        };
      });
    },
    numZonas() {
      return this.zonas.length;
    },
  },
  methods: {
    ...mapMutations("dashboard", ["setZona", "setDesde", "setHasta", "setCuadroMandoId", "resetFiltros"]),
    ...mapActions("dashboard", ["cargarDashboard", "cambiarCuadro", "aplicarFiltros"]),
  },
  mounted() {
    const qs = this.$route.query;

    if (qs.desde) {
      this.setDesde(parseDate(qs.desde));
    }

    if (qs.hasta) {
      this.setHasta(parseDate(qs.hasta));
    }

    if (qs.zona) {
      this.setZona(qs.zona);
    }

    this.cargarDashboard(qs.cuadroMandoId || 0);
  },
};
</script>
<style scoped>
.numhint {
  font-size: 0.75em;
  font-style: italic;
  color: #888;
}
</style>
