export function valorPorMes(medicion) {
  return {
    x: periodoPorMes({ mes: medicion.mes, anio: medicion.anio }),
    y: medicion.valor,
  };
}

export function periodoPorMes({ mes, anio }) {
  if (mes < 10) {
    mes = "0" + mes;
  }
  return mes + "/" + anio;
}
