<script>
import { Line } from "vue-chartjs";
export default {
  extends: Line,
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    datasets: {
      type: Array,
      default: () => [],
    },
    labels: {
      type: Array,
      default: () => [],
    },
    name: {
      type: String,
      default: "",
    },
    options: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {
    getChartdata() {
      const datasets = [];
      datasets.push(...this.datasets);

      if (this.data.length > 0) {
        datasets.push({
          label: this.name,
          backgroundColor: "transparent",
          borderColor: "#9bcb00",
          data: this.data,
        });
      }

      return {
        labels: this.labels,
        datasets: datasets,
      };
    },
  },
  data() {
    const myOptions = {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
            },
          },
        ],
      },
    };

    Object.assign(myOptions, this.options);

    return { myOptions };
  },
  methods: {
    cargar() {
      this.renderChart(this.getChartdata, this.myOptions);
    },
  },
  mounted() {
    this.cargar();
  },
  watch: {
    data() {
      this.cargar();
    },
    datasets() {
      this.cargar();
    },
  },
};
</script>
