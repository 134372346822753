import { render, staticRenderFns } from "./GraficoRezagado.vue?vue&type=template&id=c2ef3b96&"
import script from "./GraficoRezagado.vue?vue&type=script&lang=js&"
export * from "./GraficoRezagado.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports