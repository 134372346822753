<template>
  <div>
    <b-row class="graficos-resumen">
      <b-col md="12" class="mb-4" v-if="tengoDisposicion([1, 3])">
        <bloque-indicadores :bloque="1" />
      </b-col>
      <b-col md="6" v-if="tengoDisposicion([2, 3])">
        <bloque-indicadores :bloque="2" />
      </b-col>
      <b-col md="6" v-if="tengoDisposicion([2, 3])">
        <bloque-indicadores :bloque="3" />
      </b-col>
      <b-col md="12" class="mt-4" v-if="tengoDisposicion([2])">
        <bloque-indicadores :bloque="1" />
      </b-col>
    </b-row>
  </div>
</template>
<script>
import BloqueIndicadores from "./BloqueIndicadores.vue";
import { mapState } from "vuex";

export default {
  components: {
    BloqueIndicadores,
  },
  computed: {
    ...mapState("dashboard", ["cuadroMando"]),
  },
  methods: {
    tengoDisposicion(ids) {
      return ids.includes(this.cuadroMando.disposicion);
    },
  },
};
</script>
<style>
canvas {
  max-width: 100%;
}
</style>
