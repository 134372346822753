export const MALA = 1;
export const MEDIA = 2;
export const BUENA = 3;

export function nombreCalidad(n) {
  switch (n) {
    case MALA:
      return "Mala";
    case MEDIA:
      return "Media";
    case BUENA:
      return "Buena";
    default:
      return "N/A";
  }
}
