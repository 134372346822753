<template>
  <b-card title="Word Cloud">
    <b-card-body>
      <div class="words">
        <span
          v-for="item in myRecuento"
          v-bind:key="item.causa"
          class="word-span"
          :style="`font-size: ${item.size}em; font-weight: ${item.weight}`"
        >
          <span class="word">{{ item.causa | vacio }}</span>
          <span class="total">{{ item.total }}</span>
        </span>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
function calcularPorcentaje(minimo, maximo, valor) {
  if (maximo - minimo == 0) {
    // devuelvo un tamaño intermedio
    return 50;
  }

  return Math.round(((valor - minimo) * 100) / (maximo - minimo));
}

export default {
  props: ["recuento"],
  data() {
    return {
      myColors: ["#1f77b4", "#629fc9", "#94bedb", "#c9e0ef"],
    };
  },
  computed: {
    myRecuento() {
      const recuento = this.recuento;

      const totales = recuento.map((x) => x.total);
      const maximo = Math.max(...totales);
      const minimo = Math.min(...totales);
      const bolds = [400, 600, 800];

      const result = recuento.map((x) => {
        const porcentual = calcularPorcentaje(minimo, maximo, x.total);

        return {
          causa: x.causa,
          total: x.total,
          porcentual: porcentual,
          size: 1 + (porcentual * 3) / 100,
          weight: bolds[porcentual % bolds.length],
        };
      });

      return result;
    },
  },
  filters: {
    vacio(t){
      return t || "N/E";
    }

  }
};
</script>
<style scoped>
.words {
  text-align: left;
}
.words .word-span {
  display: inline-block;
  vertical-align: middle;
  font-weight: 800;
  color: #1f77b4;
  font-size: 1.5em;
  padding: 0.1em 0.25em;
  border-radius: 0.1em;
  cursor: default;
}
.words .word-span:hover {
  background: #1f77b450;
}

.words .total {
  color: #1f77b475;
  font-size: 0.5em;
  padding-left: 0.25em;
}
</style>
