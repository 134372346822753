<template>
  <b-card v-if="misBloques.length > 0">
    <div v-for="indicador in misBloques" :key="indicador.id">
      <grafico-rezagado v-if="esRezagado(indicador.naturaleza)" :rezagado="indicador" />
      <grafico-adelantado v-if="esAdelantado(indicador.naturaleza)" :indicador="indicador" />
    </div>
  </b-card>
</template>
<script>
import GraficoRezagado from "./GraficoRezagado.vue";
import GraficoAdelantado from "./GraficoAdelantado.vue";
import { esRezagado, esAdelantado } from "@/enum/NaturalezaIndicador";
import { mapGetters } from "vuex";

export default {
  components: {
    GraficoRezagado,
    GraficoAdelantado,
  },
  props: {
    bloque: Number,
  },
  computed: {
    ...mapGetters("dashboard", ["indicadoresParaBloque"]),
    misBloques() {
      return this.indicadoresParaBloque(this.bloque);
    },
  },
  methods: {
    esRezagado,
    esAdelantado,
  },
};
</script>
<style>
canvas {
  max-width: 100%;
}
</style>
