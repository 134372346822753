<script>
import { Scatter } from "vue-chartjs";
export default {
  extends: Scatter,
  props: {
    datasets: {
      type: Array,
      default: () => [],
    },
    customOptions: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  computed: {
    getChartdata() {
      return {
        datasets: this.datasets,
      };
    },
  },
  data() {
    const options = {
      responsive: true,
      maintainAspectRatio: false,
    };

    Object.assign(options, this.customOptions);

    return { options };
  },
  methods: {
    cargar() {
      this.renderChart(this.getChartdata, this.options);
    },
  },
  mounted() {
    this.cargar();
  },
  watch: {
    datasets() {
      this.cargar();
    },
  },
};
</script>
