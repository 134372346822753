<template>
  <b-card-text class="graficotipo">
    <grafica-linea :data="misMediciones" :labels="fechas" :name="indicador.nombre"></grafica-linea>
  </b-card-text>
</template>
<script>
import GraficaLinea from "@/components/TipoGrafica/GraficoLinea.vue";
import { valorPorMes, periodoPorMes } from "@/services/formatos_dashboard.js";
import { mapGetters } from "vuex";

export default {
  components: {
    GraficaLinea,
  },
  props: {
    indicador: Object,
  },
  data() {
    return {
      mediciones: [],
      periodos: [],
    };
  },
  computed: {
    ...mapGetters("dashboard", ["getValores"]),
    misValores() {
      return this.getValores(this.indicador.id);
    },
    misMediciones() {
      return this.misValores.mediciones;
    },
    fechas() {
      return this.misValores.periodos.map(periodoPorMes);
    },
  },
};
</script>
