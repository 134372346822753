export const INVERSA = 1;
export const DIRECTA = 2;

export function nombreTendencia(n) {
  switch (n) {
    case INVERSA:
      return "Inversa";
    case DIRECTA:
      return "Directa";
    default:
      return "N/A";
  }
}
