<template>
  <b-card-text class="graficotipo">
    <b-row>
      <b-col>
        <grafica-nube-dispersion :datasets="datasetDispersion"></grafica-nube-dispersion>
      </b-col>
      <b-col>
        <grafica-linea :datasets="datasetsAdelantados" :labels="fechas"></grafica-linea>
      </b-col>
    </b-row>
    <tabla-coeficientes
      :rezagado="rezagado"
      :adelantado="indicadorAdelantado"
      :coeficientes="misValores.tabla"
    ></tabla-coeficientes>
  </b-card-text>
</template>
<script>
import GraficaLinea from "@/components/TipoGrafica/GraficoLinea.vue";
import GraficaNubeDispersion from "@/components/TipoGrafica/GraficaNubeDispersion.vue";
import TablaCoeficientes from "./TablaCoeficientes.vue";
import { valorPorMes, periodoPorMes } from "@/services/formatos_dashboard.js";
import { mapGetters } from "vuex";

export default {
  components: {
    GraficaLinea,
    GraficaNubeDispersion,
    TablaCoeficientes,
  },
  props: {
    rezagado: Object,
  },
  computed: {
    ...mapGetters("dashboard", ["getValores"]),
    misValores() {
      return this.getValores(this.rezagado.id);
    },
    datasetDispersion() {
      return [
        {
          label: this.rezagado.nombre,
          backgroundColor: "#009bcb",
          borderColor: "#009bcb",
          data: this.misValores.dispersion,
        },
      ];
    },
    indicadorAdelantado() {
      return this.misValores.adelantado;
    },
    datasetsAdelantados() {
      const datosRezagado = this.misValores.medicionesRezagado;
      const datosAdelantado = this.misValores.medicionesAdelantado;
      const datosSimulados = this.misValores.medicionesRezagadoSimuladas;
      const indicadorAdelantado = this.indicadorAdelantado;

      const simuladosAdelantado = datosSimulados.map((m) => m.x);
      const simuladosRezagado = datosSimulados.map((m) => m.y);
      const pvs = this.paddingDeValoresNulosDelPeriodoNoSimulado;

      const dataset = [
        {
          label: this.rezagado.nombre,
          borderColor: "#9b00cb",
          backgroundColor: "#9b00cb25",
          data: datosRezagado,
        },
        {
          label: indicadorAdelantado.nombre,
          borderColor: "#9bcb00",
          backgroundColor: "#9bcb0025",
          data: datosAdelantado,
        },
        {
          label: this.rezagado.nombre + " (simulado)",
          borderColor: "#9b00cb80",
          backgroundColor: "#9b00cb15",
          borderDash: [5, 5],
          data: [...pvs, ...simuladosRezagado],
        },
        {
          label: indicadorAdelantado.nombre + " (simulado)",
          borderColor: "#9bcb0080",
          backgroundColor: "#9bcb0015",
          borderDash: [5, 5],
          data: [...pvs, ...simuladosAdelantado],
        },
      ];

      // Todo: en realidad, debo generar un elemento en el dataset por cada
      // conjunto de mediciones de cada indicador adelantado (cuando se permita)

      return dataset;
    },
    paddingDeValoresNulosDelPeriodoNoSimulado() {
      return this.misValores.periodos.map((p) => {
        return null;
      });
    },
    fechas() {
      return [...this.misValores.periodos, ...this.misValores.periodosSimulados].map(periodoPorMes);
    },
  },
};
</script>
