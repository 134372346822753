<template>
  <b-alert show variant="warning" class="text-center">
    <div>
      <b> No hay configurado ningún indicador </b>
    </div>
    <div v-if="cuadroMandoId">
      <b-link :to="`/administracion/cuadros-de-mando/asignar-indicadores/${cuadroMandoId}`">
        Diseñar este cuadro de mando
      </b-link>
    </div>
    <div v-else>
      Primero, <b-link to="/administracion/cuadros-de-mando/crear">debería crear un cuadro de mando</b-link>
    </div>
  </b-alert>
</template>

<script>
export default {
  props: ["cuadroMandoId"],
};
</script>
