<template>
  <b-form-group :label="label">
    <b-form-datepicker
      required
      v-model="myValue"
      @input="$emit('input', arguments[0])"
      placeholder="Selecciona una fecha"
      :date-format-options="$dateFormatOptions"
      locale="es"
    ></b-form-datepicker>
    <slot></slot>
  </b-form-group>
</template>

<script>
export default {
  props: {
    label: String,
    value: [Date, Number, String],
  },
  data() {
    return {
      myValue: this.value,
    };
  },
};
</script>
